import { flow } from "fp-ts/lib/function";
/*eslint-disable*/
import * as R from "ramda";
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMedia } from "use-media";

import RegisterSection from "../../components/chat-channel/ChannelHeader";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
// import { CardsThree } from "../conversational-ai-platform";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import {
  DropdownForLeft,
  MarketPlaceItemsAutomateV2,
  MarketPlaceItemsBotsV2,
  MarketPlaceLoader,
  withBaseUrl,
} from "../../react/ApplicationsMarketplace";
import {
  HrItSupport_November,
  HrItSupportMobile_November,
} from "../conversational-ai-platform";
import { MarketPlaceLoadStateHandler } from "../../react/MarketPlaceAssistants";
import loadScript from "../../react/loadscript";
import {
  AppSwitchTop,
  HeaderSwitch,
  useHeader,
} from "./chatbotmarketplacesecond";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const axios = require("axios").default;
const topImage = require("../../assets/img/feature/dialog/chatbot_builder_sec_7.png");

export const blogImg = require("../../assets/img/key-resources/bc_1_4.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

export const cardOne = require("../../assets/img/feature/home/sec2.png");
export const cardTwo = require("../../assets/img/feature/home/sec3.png");

const it_support = require("../../assets/img/home_page_assist/it_support.png");
const hr_support = require("../../assets/img/home_page_assist/hr_support.png");

const it_support_mob = require("../../assets/img/home_page_assist/it_support_mob.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

// const withBaseUrl = (url) => {
//   // var base_url = "http://localhost:2019";
//   var base_url = window.location.origin;
//   return `${base_url}${url}`;
// };

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for IT Support",
    content: `Deliver faster and superior IT support, powered by conversational ai and app workflow automation. Augment your IT Helpdesk team with intelligent chatbot to scale your support easily in a few clicks.  `,
    content_1: `No coding required.`,
    linkAddress: "/conversational-ai-platform/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for HR Support",
    content: `Transform your employee experience with our AI-powered HR chatbot with prebuilt HR workflow automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/hr-chatbot",
    backgroundClass: "bg_card_2",
  },
];

// data  = {
//   type : "loading"
// } | {
//   type : "loaded"
//   data : []
// }

// {
// domain : "workflow" ,
// step : "listapps" | "listworkflows",
// apiState :  { status : "loaded" , data : {
//    apps : [],
//    categories : [],
//    selectedApp : "",
//    selectedCategory : ""
//  }
// }

export function useHeaders(initialValue = "chatbot") {
  const possibleValues = ["chatbot", "workflow"];
  const defaultValue = "chatbot";
  const [value, setValue] = React.useState(() => {
    const toSet = possibleValues.includes(initialValue)
      ? initialValue
      : defaultValue;
    return toSet;
  });
  const update = (newValue) => {
    if (possibleValues.includes(newValue)) {
      setValue(newValue);
    }
  };
  return [value, update];
}

const useWorkflowApps = () => {
  // { type: "loaded", apps: [], categories: [] }
  const [apiWorkflowApps, setWorkflowApps] = React.useState({
    type: "loading",
  });

  const apiDataReferecnce = React.useRef(null);

  function fetchWorkflowData() {
    const initializeSelectApp = (item) => ({
      isSelected: false,
      name: item.appName,
      categoryId: item.categoryId,
      id: item.appId,
      icon: item.icon,
    });

    const initializeCatogories = (item) => ({ ...item, isSelected: false });

    const sortByNameCaseInsensitive = R.compose(R.toLower, R.prop("name"));
    // return Promise.resolve(staticResponse);
    // const url = process.env.GATSBY_APP_APPS_LIST;
    // const url = "http://localhost:2019/api/assistant//v2/workflow-categories-apps"
    const url = withBaseUrl("/api/assistant/v2/workflow-categories-apps");
    // console.log(process.env.GATSBY_APP_APPS_LIST);
    const options = {
      url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    if (apiDataReferecnce.current != null) {
      setWorkflowApps(apiDataReferecnce.current);
    } else {
      axios(options)
        .then((resp) => resp.data)
        .then((data) => data.output)
        .then((output) => {
          const pairs = R.toPairs(output);
          const categories = R.map(([name, appArray]) => {
            const id = appArray[0].categoryId;
            const count = appArray.length;
            return {
              name,
              id,
              count,
            };
          })(pairs);

          const apps = R.map(([, appArray]) => {
            return appArray;
          })(pairs);

          console.log("DOING :: apps", apps);

          const allApps = R.reduce(
            (x, y) => {
              return [...x, ...y];
            },
            [],
            apps
          );

          return {
            categories,
            apps: allApps,
          };
        })
        .then((data) => {
          const applications = R.map(initializeSelectApp, data.apps);
          const sortedApps = R.sortBy(sortByNameCaseInsensitive, applications);
          // console.log(data.categories, initializeCatogories(data.categories))
          const categoriesModified = R.map(initializeCatogories)(
            data.categories
          );

          const categoriesDomianFormat = [
            {
              name: "All",
              id: "all",
              isSelected: true,
              count: sortedApps.length,
            },
            ...categoriesModified,
          ];
          const appsDomainFormat = sortedApps;
          console.log("DOING :: setWorkflowApps", {
            type: "loaded",
            apps: appsDomainFormat,
            categories: categoriesDomianFormat,
          });
          setWorkflowApps({
            type: "loaded",
            apps: appsDomainFormat,
            categories: categoriesDomianFormat,
          });
          apiDataReferecnce.current = {
            type: "loaded",
            apps: appsDomainFormat,
            categories: categoriesDomianFormat,
          };
        })
        .catch((error) => error);
    }

    // let x = Promise.resolve(Mdatas)
  }

  return [apiWorkflowApps, fetchWorkflowData];
};

const useBotCategories = () => {
  // { type: "loaded", apps: [], categories: [] }
  const [apiCategoriesAndBots, setBotCategories] = React.useState({
    type: "loading",
  });

  const apiDataReferecnce = React.useRef(null);

  function fetchBotData() {
    const initializeSelectApp = (item) => ({
      isSelected: false,
      name: item.appName,
      categoryId: item.categoryId,
      id: item.appId,
      icon: item.icon,
    });

    const initializeCatogories = (item) => ({ ...item, isSelected: false });

    const sortByNameCaseInsensitive = R.compose(R.toLower, R.prop("name"));
    // return Promise.resolve(staticResponse);
    // const url = process.env.GATSBY_APP_APPS_LIST;
    // const urlForCategories = "http://localhost:2019/api/assistant//v2/bots/categories"
    const urlForCategories = withBaseUrl("/api/assistant/v2/bots/categories");
    // const urlForBots = "http://localhost:2019/api/assistant//v2/bots/search"
    const urlForBots = withBaseUrl("/api/assistant/v2/bots/search");

    // const url = "/api/assistant/automate-marketplace"
    // console.log(process.env.GATSBY_APP_APPS_LIST);
    const makeAxiosoptions = (url) => ({
      url: url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });

    if (apiDataReferecnce.current != null) {
      setBotCategories(apiDataReferecnce.current);
    } else {
      const getCategories = axios(makeAxiosoptions(urlForCategories))
        .then((resp) => resp.data)
        .then((data) => data.output);
      const getBots = axios(makeAxiosoptions(urlForBots))
        .then((resp) => resp.data)
        .then((data) => data.output);

      console.log(
        "DOING :: getCategories",
        getCategories,
        getBots,
        makeAxiosoptions(urlForCategories)
      );

      Promise.all([getCategories, getBots])
        .then(([categories, bots]) => {
          const sortByDownloads = R.sort(R.descend(R.prop("downloaded_count")));
          // console.log("DOING :: API dune", bots, sortByDownloads(bots));

          const sortedBots = sortByDownloads(bots);

          console.log("DOING :: api done", categories, bots, sortedBots);

          const categoriesDomianFormat = [
            { name: "All", id: "all" },
            ...categories,
          ];
          // const botsDomianFormat = [...bots]
          console.log("DOING :: setWorkflowApps", {
            type: "loaded",
            apps: sortedBots,
            categories: categoriesDomianFormat,
          });
          setBotCategories({
            type: "loaded",
            bots: sortedBots,
            categories: categoriesDomianFormat,
          });
          apiDataReferecnce.current = {
            type: "loaded",
            bots: sortedBots,
            categories: categoriesDomianFormat,
          };
        })
        .catch((error) => error);
    }
  }

  return [apiCategoriesAndBots, fetchBotData];
};

export default function MarketPlace(props) {
  const headerList = [
    { name: "Chatbots", value: "chatbot" },
    { name: "App Workflows", value: "workflow" },
  ];
  const [header, updateHeader] = useHeader("chatbot");
  const isSmall = useMedia({ maxWidth: "520px" });

  const [apiWorkflowApps, fetchWorkflowData] = useWorkflowApps();
  const [apiCategoriesAndBots, fetchBotData] = useBotCategories();
  console.log("DOING :: apiWorkflowApps", apiWorkflowApps);

  React.useEffect(() => {
    if (header === "workflow") {
      fetchWorkflowData();
    }

    if (header === "chatbot") {
      fetchBotData();
    }
  }, [header]);

  React.useEffect(() => {
    console.log("DOING :: apiWorkflowApps", apiWorkflowApps);
  }, [apiWorkflowApps]);

  React.useEffect(() => {
    console.log("DOING :: apiCategoriesAndBots", apiCategoriesAndBots);
  }, [apiCategoriesAndBots]);

  const loadWorkflow = () => {
    console.log("DOING :: ", apiWorkflowApps);
    return apiWorkflowApps.type === "loaded" ? (
      <MarketPlaceItemsAutomateV2
        data={apiWorkflowApps}
        startWith={"appList"}
      />
    ) : (
      <MarketPlaceLoader />
    );
  };

  const loadBots = () => {
    console.log("DOING :: ", apiWorkflowApps);
    return apiCategoriesAndBots.type === "loaded" ? (
      <MarketPlaceItemsBotsV2
        data={apiCategoriesAndBots}
        startWith={"botList"}
      />
    ) : (
      <MarketPlaceLoader />
    );
  };

  return (
    <>
      <TitleAndMetaTags
        title="Chatbot Template Marketplace – IT & HR Chatbot Marketplace"
        description="Pre built chatbot marketplace, create AI Chatbot with easy-to use pre-built bot & Pre-built app workflow templates."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogImage={topImage}
        ogTitle="Chatbot Template Marketplace – IT & HR Chatbot Marketplace"
        ogDescription="Pre built chatbot marketplace, create AI Chatbot with easy-to use pre-built bot & Pre-built app workflow templates."
      />

      <Container>
        <Layout
          backgroundColor={" bg_slider_home pad_0"}
          logoFor="ASSISTANT"
          product="ASSISTANT"
        >
          <PostHeaderSection />
          <div className="overall_wrapper">
            <div className="market_place">
              <>
                <div className="marketplace_wrapper app_workflow_section_marketplace background_marketplace_assist">
                  <div className="container">
                    {!isSmall ? (
                      <HeaderSwitch
                        header={header}
                        updateHeader={updateHeader}
                      />
                    ) : (
                      <div class="row">
                        <div className="col-lg-3 app-market-main-container-left-side higher-z-index">
                          <div className="app-market-left-panel-new app-market-left-panel-app-market-first pb-0">
                            <DropdownForLeft
                              data={headerList}
                              for={"Category"}
                              itemToString={(item) =>
                                item ? <>{item.name}</> : ""
                              }
                              setSelected={(item) => updateHeader(item.value)}
                              selected={headerList.find(
                                (x) => x.value === header
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {header === "chatbot" ? loadBots() : loadWorkflow()}
                  </div>
                </div>
              </>
            </div>
          </div>
          {isSmall ? (
            <HrItSupportMobile_November
              tabContent={tabsForHrIt_November}
              additionalClass={"marketplace_hr_it_container_upd"}
              header={
                "Modern AI-chatbot automation platform designed for superior employee experience"
              }
              imagesToPreload={tabsForHrItImagesDesktop_November}
            />
          ) : (
            <HrItSupport_November
              tabContent={tabsForHrIt_November}
              additionalClass={"marketplace_hr_it_container_upd"}
              header={
                "Modern AI-chatbot automation platform designed for superior employee experience"
              }
              imagesToPreload={tabsForHrItImagesDesktop_November}
            />
          )}

          {/* <GoToPopup />
          <CookiesPoup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

function PostHeaderSection() {
  return (
    <RegisterSection
      rightImage={topImage}
      backgroundColor={" bg_slider_home "}
      altImage="App Workflow Marketplace for Chatbot"
      additionalClass="marketplace-assist-header-bottom marketplace-mobile-center-text"
    >
      <RegisterSection.FormWithContent>
        <RegisterSection.MainHeader>
          Jump-start your chatbot with easy-to use pre-built bot & Pre-built app
          workflow templates.
        </RegisterSection.MainHeader>
        <RegisterSection.SubHeader>
          Join several leading companies using workativ in providing smarter
          employee experience using conversational ai chatbots with app workflow
          automation.
        </RegisterSection.SubHeader>
      </RegisterSection.FormWithContent>
    </RegisterSection>
  );
}
function CardsThree() {
  return (
    <section className="helpdesk_solutions card_two">
      <div className="suprcharging_benefits" id="numeric">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="main-slider-left">
                <h2 className="font-section-header marketplace-two-card-header">
                  Transform your workplace support with chatbot automation now
                </h2>
              </div>
              {/*How it Works Content*/}

              <div className="cards_wrapper_home">
                {cardData.map((cards) => {
                  let changeColor =
                    cards.backgroundClass === "bg_card_1"
                      ? "#F1FAF4 0% 0% no-repeat padding-box"
                      : "#FBF9F4 0% 0% no-repeat padding-box";
                  return (
                    <div
                      className={`${"col-lg-6 col-md-6 col-sm-6 col-xs-12"} ${
                        cards.backgroundClass
                      }`}
                      style={{ background: changeColor }}
                    >
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <div className="cards_soon_wrapper">
                          <img src={cards.image} alt={cards.alt} />
                          {cards.cards_soon && (
                            <span className="cards_soon">
                              <div class="coming_soon_wrapper">
                                <span class="coming_soon">Coming soon</span>
                              </div>
                            </span>
                          )}
                        </div>
                        <h4 className="cards_slider_wrap font-section-sub-header-small-bold">
                          {cards.header}
                        </h4>
                        <p className="font-section-normal-text">
                          {cards.content}
                        </p>
                        <h6 className="font-medium font-section-normal-text-medium">
                          {cards.content_1}
                        </h6>
                        <a
                          href={cards.linkAddress}
                          className="url_manipulation font-section-normal-text-medium"
                        >
                          Learn more{" "}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export function ReachToUsSection() {
  const [showModal, setShowModal] = useState(false);

  const [state, setState] = useState({ useCase: "", email: "" });

  function closemodel(callback) {
    document.getElementById("exampleModalCenter").className = "modal fade";
    document.getElementById("exampleModalCenter").style.display = "none";
    if (callback) {
      callback();
    }

    setShowModal(false);
  }

  function enableSubmit() {
    return state.useCase.length > 0 && state.email.length > 0;
  }

  useEffect(() => {
    // console.log("load scropt");
    if (!window._load_script) {
      loadScript("/marketPlaceScript.js")
        .then((script) => {
          // console.log("load scropt success");
          // console.log(script);
          // Grab the script object in case it is ever needed.
          // this.mapScript = script;
          // this.setState({ apiLoaded: true });
        })
        .catch((err) => {
          console.log("load scropt error");
          console.error(err.message);
        });
    }
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <section className="apps_integrations bg_marketpalce">
      <div className="cogni_fav_apps ">
        <div className="container">
          <div className="marketplace_assist_reach_div">
            <div className="cc_feature_apps_header">
              {isSmall ? (
                <h3
                  className="font-section-sub-header
                "
                >
                  Didn’t find the automation you were looking for? No worries,
                  we will create one for you.
                </h3>
              ) : (
                <h3
                  className="font-section-sub-header
                "
                >
                  Didn’t find the automation you were looking for? <br></br>No
                  worries, we will create one for you.
                </h3>
              )}
              <div className="modal_submit_form marketplace_assist_reach_button marketplace_assist_reach_button_mobile">
                <button
                  type="button"
                  className="btn btn-primary font-section-normal-text"
                  data-toggle="modal"
                  data-target="#exampleModalCenter1"
                  onClick={() => setShowModal(true)}
                >
                  Reach out to us
                </button>

                <div
                  className="modal fade show"
                  id="exampleModalCenter1"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  style={{ display: showModal ? "flex" : "none" }}
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div
                      className="modal-content modal-content-popup"
                      id="beta-detail-page"
                    >
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={() => closemodel()}
                        >
                          <span className="btn_close_popup" aria-hidden="true">
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="modal-body-header">
                        <div className="modal-body">
                          <form
                            id="_form_22_"
                            className="_form _form_22 _inline-form  _dark"
                            noValidate
                          >
                            <input type="hidden" name="u" defaultValue={22} />
                            <input type="hidden" name="f" defaultValue={22} />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" defaultValue={0} />
                            <input type="hidden" name="m" defaultValue={0} />
                            <input
                              type="hidden"
                              name="act"
                              defaultValue="sub"
                            />
                            <input type="hidden" name="v" defaultValue={2} />
                            <div className="_form-content">
                              <div className="_form_element _x41893381 _full_width _clear">
                                <div className="_form-title">
                                  Request for Use Case
                                </div>
                              </div>
                              <div className="_form_element _x65116826 _full_width _clear">
                                <div className="_html-code">
                                  <p>
                                    Tell us of your use case requirement in a
                                    few lines.
                                  </p>
                                </div>
                              </div>
                              <div className="_form_element _field16 _full_width ">
                                <label className="_form-label">
                                  Automation use case *
                                </label>
                                <div className="_field-wrapper">
                                  <textarea
                                    id="useCase"
                                    name="field[16]"
                                    placeholder="Automation use case "
                                    required
                                    defaultValue={""}
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        useCase: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_form_element _x68473619 _full_width ">
                                <label className="_form-label">Email*</label>
                                <div className="_field-wrapper">
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    placeholder="Type your email"
                                    required
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_button-wrapper _full_width">
                                <button
                                  id="_form_22_submit"
                                  className="_submit"
                                  type="submit"
                                  disabled={!enableSubmit()}
                                  style={{
                                    pointerEvents: enableSubmit()
                                      ? "visible"
                                      : "none",
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="_clear-element" />
                            </div>
                            <div
                              className="_form-thank-you"
                              style={{ display: "none" }}
                            />
                          </form>
                        </div>
                        <div className="modal_body_right">
                          <img
                            src={require("../../assets/images/landing/register.png")}
                            alt="register"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const tabsForHrIt_November = [
  {
    header: "IT Support",
    hasZeroPaddedDesktopImage: false,
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container align-self-center pb-0">
          <h5 className="font-section-normal-text-medium support_workativ_heading">
            Workativ for
          </h5>
          <h3 className="font-section-normal-text-medium-link heading_services_support_home">
            IT Support & Service Desk
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Resolve your employee IT issues instantly using chatbot powered by
            conversational ai and app workflow automation.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Augment and free up your IT Helpdesk team with intelligent chatbot
            automation and scale your support easily in few clicks.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/it-helpdesk-chatbot/"
          >
            Learn More &nbsp; &#8594;
          </a>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container">
          <img src={it_support} alt="it_support"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h5 className="font-section-normal-text-medium support_workativ_heading">
          Workativ for
        </h5>
        <h3 className="font-section-normal-text-medium-customer-banner heading_services_support_home">
          IT Support & Service Desk
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Resolve your employee IT issues instantly using chatbot powered by
          conversational ai and app workflow automation.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          Augment and free up your IT Helpdesk team with intelligent chatbot
          automation and scale your support easily in few clicks.
        </p>
        <img src={it_support} alt="it_support"></img>
      </React.Fragment>
    ),
  },
  {
    header: "HR Support",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container align-self-center pb-0">
          <h5 className="font-section-normal-text-medium support_workativ_heading">
            Workativ for
          </h5>
          <h3 className="font-section-normal-text-medium-link heading_services_support_home">
            HR Support automation
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            If your HR teams are struggling with frequent employee queries,
            manual onboarding, and ever growing employee request - you need an
            intelligent HR Chatbot with app workflows.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Automate the mundane employee requests, and free up your HR teams to
            focus on growth.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/hr-chatbot/"
          >
            Learn More &nbsp; &#8594;
          </a>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container">
          <img src={hr_support} alt="hr_support"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h5 className="font-section-normal-text-medium support_workativ_heading">
          Workativ for
        </h5>
        <h3 className="font-section-normal-text-medium-customer-banner heading_services_support_home">
          HR Support automation
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          If your HR teams are struggling with frequent employee queries, manual
          onboarding, and ever growing employee request - you need an
          intelligent HR Chatbot with app workflows.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          Automate the mundane employee requests, and free up your HR teams to
          focus on growth.
        </p>
        <img src={hr_support} alt="hr_support"></img>
      </React.Fragment>
    ),
  },
];
const tabsForHrItImagesDesktop_November = [it_support, hr_support];
